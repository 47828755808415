import logo from './logo.svg';
import React from 'react';
import './App.css';
import UnityGame from './UnityGame';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function App() {

  React.useEffect(() => {
    // Ensure the document body has no scroll
    document.body.style.overflow = 'hidden';
  }, []);

  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
    // <div style={{ width:  '100vw', height: '100vh' }}>
    //       <UnityGame />
    //   </div>

    <Router>
    <div>
      {/* <h1>My Unity WebGL Game</h1> */}
      <Routes>
        <Route path="/" element={    
    <div style={{ width:  window.innerWidth, height: window.innerHeight, overflow: 'hidden'  }}>
    <UnityGame />
    </div>} />
      </Routes>
    </div>
  </Router>


  );
}

export default App;
